import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "poposki_first_head_coach_match" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Сашко Попоски вчера в своем дебютном матче Латвийской Вирслиги в статусе исполняющего обязанности главного тренера Рижского футбольного клуба выиграл у БФК Даугавпилс со счетом 7-1.</p>
          <p>Catenaccio Sports Agency поздравляет мистера Попоски и желает ему всяческих успехов в будущих играх.</p>
        </div>
      </div>
    )
  }
}